<template>
  <div class="message">
    <van-notice-bar
      mode="closeable"
      color="#1989fa"
      background="#ecf9ff"
      left-icon="warning-o"
      ref="messageNotice"
    >
      超过30天的消息，请前往PC端查看</van-notice-bar>
    <van-cell
      v-for="(item, index) in messageTypeList"
      :key="index"
      :title="item.typeName"
      center
      @click="$router.push(`/message/systemMessage/${item.type}`)"
    >
      <template #icon>
        <svg-icon
          :icon-class="item.type | iconName"
          className="myIcon"
        />
      </template>
      <template #label>
        <p
          class="van-cell__label"
          v-if="item.type === '0'"
        >
          {{
            item.newMessage
              ? `${JSON.parse(item.newMessage.content).name}有${item.newMessage.titleName}`
              : "暂无消息"
          }}
        </p>
        <p
          class="van-cell__label"
          v-if="item.type === '1'"
        >
          {{
            item.newMessage
              ? `${JSON.parse(item.newMessage.content).name || ''}有${item.newMessage.titleName}`
              : "暂无消息"
          }}
        </p>
        <p
          class="van-cell__label"
          v-if="item.type === '3'"
        >
          <span v-if="item?.newMessage?.title === '103'">
            {{ JSON.parse(item.newMessage.content).code +
              JSON.parse(item.newMessage.content).context }}
          </span>
          <span
            v-else-if="item?.newMessage?.title === '104'"
            v-html="JSON.parse(item.newMessage.content).context"
            class="commonMoreEllipsis"
          >
          </span>
          <span v-else>暂无消息</span>
        </p>
        <p
          class="van-cell__label"
          v-if="item.type === '5'"
        >
          <span v-if="item?.newMessage?.title === '3'">
            {{ `${JSON.parse(item.newMessage.content).title}有${item.newMessage.titleName}` }}
          </span>
          <span v-else>
            {{
              item.newMessage
                ? `${JSON.parse(item.newMessage.content).name}有${item.newMessage.titleName}`
                : "暂无消息"
            }}
          </span>
        </p>
        <p
          class="van-cell__label"
          v-if="item.type === '7'"
        >
          {{
            item.newMessage
              ? `
          您的工作证将于${JSON.parse(item.newMessage.content).certificateValidityPeriodEnd}到期，请及时联系管理员更新！
          `: "暂无消息"
          }}
        </p>
        <p
          class="van-cell__label"
          v-if="item.type === '6'"
        >
          {{
            item.newMessage
              ? `${JSON.parse(item.newMessage.content).name}有${item.newMessage.titleName}`
              : "暂无消息"
          }}
        </p>
      </template>
      <template #default>
        <p>{{ item?.newMessage?.time }}</p>
        <van-badge
          :content="item.count"
          max="99"
          :class="{ isShow: !item.count }"
        />
      </template>
    </van-cell>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'message',
  computed: {
    ...mapState('count', ['messageTypeList'])
  },
  methods: {
    ...mapActions('count', ['getMessageCenList'])
  },
  activated() {
    this.getMessageCenList()
    this.$refs.messageNotice.show = true
  },
  filters: {
    // 格式化消息图标
    iconName(val) {
      if (val === '0') {
        return 'unit'
      } else if (val === '1') {
        return 'equipment'
      } else if (val === '2') {
        return 'material'
      } else if (val === '3') {
        return 'system'
      } else if (val === '4') {
        return 'task'
      } else if (val === '5') {
        return 'agreementMessage'
      } else if (val === '6') {
        return 'supplier'
      } else if (val === '7') {
        return 'userMessage'
      } else {
        return 'unit'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.message {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  overflow: auto;

  ::v-deep {
    .isShow {
      opacity: 0;
    }

    .van-cell--center {
      .myIcon {
        width: 32px;
        height: 32px;
        margin-right: 16px;
      }

      .van-cell__title {
        flex: 1;
        overflow: hidden;

        >span {
          font-weight: 700;
        }

        .van-cell__label {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .van-cell__value {
        flex: none;
      }
    }
  }
}
</style>
